import React, { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "../../styles/mainBanner.css";
import { mainContent } from "../../content/mainContent";
import ContextForm from "../../contexts/ContextForm";
import Header from "../Header/Header";

const MainBanner = () => {
  let modal = useContext(ContextForm);

  let showMainContent = mainContent.map((item) => {
    return (
      <div key={item.id} className="main__item">
        <SwiperSlide key={item.id}>
          <picture>
            <source
              srcSet={`./img/${item.mainBg}`}
              width={480}
              media="(min-width: 997px)"
              alt={`${item.titleTop} ${item.titleBottom}`}
            />
            <img
              src=""
              width={480}
              alt={`${item.titleTop} ${item.titleBottom}`}
            />
          </picture>
          <div className="main__item_content">
            <div className="main__item_content-relative">
              <div>
                <h3 className="main__item_title">{item.titleTop}</h3>
                <h3 className="main__item_title">{item.titleBottom}</h3>
              </div>
              <p className="main__item_subtitle">{item.subtitle}</p>
              <button
                className="main__item_link"
                href={item.link}
                onClick={modal.dispatch}
              >
                {item.linkText}
              </button>
            </div>
          </div>
        </SwiperSlide>
      </div>
    );
  });

  return (
    <>
      <Swiper
        slidesPerView={4}
        spaceBetween={0}
        simulateTouch={false}
        className="mySwiper main__item_bg"
      >
        <div className="main__item_header">
          <Header />
        </div>
        {showMainContent}
      </Swiper>
    </>
  );
};

export default MainBanner;
