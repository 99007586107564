import React from "react";
import "../../styles/ourServices.css";
import { NavLink } from "react-router-dom";
import { ourServicesContent } from "../../content/ourServicesContent";

const OurServices = () => {
  const showItem = ourServicesContent.map((item) => {
    return (
      <div key={item.id} className={`ourServices__item ${item.img}`}>
        <div className="ourServices__item_container">
          <div className="item__title">
            <h3 className="item__title_text">{item.title}</h3>
          </div>
          <p className="item__description">{item.description}</p>
          <div className="item__link">
            <NavLink to={item.buttonConsultationLink}>
              {item.buttonConsultation}
            </NavLink>
          </div>
        </div>
      </div>
    );
  });

  return (
    <section className="ourServices" id="services-scroll">
      <div className="ourServices__container">
        <div className="ourServices__title">
          <h2 className="ourServices__title_text">Our Services</h2>
        </div>
        <div className="ourServices__items">{showItem}</div>
      </div>
    </section>
  );
};

export default OurServices;
