import React, { useState, useEffect } from "react";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
  FaLinkedin,
} from "react-icons/fa";
import "../../styles/footer.css";
import { footerContent } from "../../content/footerContent";
import logoFooter from "../../assets/images/logo_footer.png";
import license1 from '../../assets/images/judicial_department.png';
import license2 from '../../assets/images/dubai_courts.png';
import Link from "react-scroll/modules/components/Link";
import { useLocation, NavLink } from "react-router-dom";

const Footer = () => {
  const [widthScreen, setWidthScreen] = useState(0);
  let { pathname } = useLocation();

  useEffect(() => {
    setWidthScreen(window.screen.width);
  }, []);

  const kindsLeft = footerContent.links.map(
    (item) =>
      item.id <= 5 && (
        <div key={item.id} className="footer__kinds_item">
          <NavLink key={item.id} to={item.link}>
            {item.name}
          </NavLink>
        </div>
      )
  );
  const kindsRight = footerContent.links.map(
    (item) =>
      item.id > 5 && (
        <div key={item.id} className="footer__kinds_item">
          <NavLink key={item.id} to={item.link}>
            {item.name}
          </NavLink>
        </div>
      )
  );

  return (
    <footer className="footer" id="contacts-scroll">
      <div className="footer__container">
        <div className="footer__top">
          <div className="footer__logo">
            {pathname === "/" ? (
              <div className="footer__logo_top">
                <Link
                  activeClass="active"
                  to={widthScreen > 996 ? "home-scroll" : "home-scroll-toggle"}
                  spy={true}
                  smooth={true}
                  duration={2000}
                  delay={100}
                >
                  <img
                    width={54}
                    className="footer__logo_img"
                    src={logoFooter}
                    alt="logo Emirates Law"
                  />
                </Link>
                <Link
                  activeClass="active"
                  to={widthScreen > 996 ? "home-scroll" : "home-scroll-toggle"}
                  spy={true}
                  smooth={true}
                  duration={2000}
                  delay={100}
                >
                  <span className="footer__logo_text">
                    {footerContent.logo}
                  </span>
                </Link>
              </div>
            ) : (
              <div className="footer__logo_top">
                <NavLink to="/">
                  <img
                    width={54}
                    className="footer__logo_img"
                    src={logoFooter}
                    alt="logo Emirates Law"
                  />
                </NavLink>
                <NavLink to="/">
                  <span className="footer__logo_text">
                    {footerContent.logo}
                  </span>
                </NavLink>
              </div>
            )}
            <div>
              <h2 className="licensed__title">
                Licensed To Appear Before All UAE Courts:
              </h2>
              <div className="licensed__image-box">
                <div className="licensed__image-box_item">
                  <img
                    width={200}
                    loading="lazy"
                    src={license1}
                    alt="judicial_department"
                  />
                </div>
                <div className="licensed__image-box_item">
                  <img
                    width={200}
                    loading="lazy"
                    src={license2}
                    alt="dubai_courts"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="footer__kinds">
            <div className="footer__kinds_left">{kindsLeft}</div>
            <div className="footer__kinds_right">{kindsRight}</div>
          </div>
          <address className="footer__contacts">
            <div className="footer__contacts_tel">
              <div className="footer__contacts_title">
                {footerContent.titleTel}
              </div>
              <a href={"tel:" + footerContent.tel}>{footerContent.tel}</a>
            </div>
            <div className="footer__contacts_email">
              <div className="footer__contacts_title">
                {footerContent.titleEmail}
              </div>
              <a href={"mailto:" + footerContent.email}>
                {footerContent.email}
              </a>
            </div>
            <div className="footer__contacts_address">
              <div className="footer__contacts_title">
                {footerContent.titleAddress}
              </div>
              <a href={footerContent.addressLink} target="blank">
                {footerContent.address}
              </a>
            </div>
            <div className="footer__contacts_social-networks">
              <a href={footerContent.linkedin} target="blank">
                <FaLinkedin />
              </a>
              <a href={footerContent.instagram} target="blank">
                <FaInstagramSquare />
              </a>
              <a href={footerContent.twitter} target="blank">
                <FaTwitterSquare />
              </a>
              <a href={footerContent.facebook} target="blank">
                <FaFacebookSquare />
              </a>
            </div>
          </address>
        </div>
        <div className="footer__middle">{footerContent.description}</div>
        <div className="footer__bottom">
          <time className="footer__resived" dateTime="2022">
            {footerContent.resived}
          </time>
          <NavLink className="footer__policy" to={footerContent.policyLink}>
            {footerContent.policy}
          </NavLink>
          <NavLink className="footer__policy" to={footerContent.termsOfUseLink}>
            {footerContent.termsOfUse}
          </NavLink>
          <div className="footer__dib">
            <span>
              {footerContent.dib.split(" ")[0]}{" "}
              {footerContent.dib.split(" ")[1]}{" "}
            </span>
            <a href="https://dib.company/en">
              {footerContent.dib.split(" ")[2]}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
