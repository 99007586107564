import React, { useContext, useState } from "react";
import "../../styles/modalForm.css";
import ContextForm from "../../contexts/ContextForm";
import CustomModal from "./CustomModal";
import { sendMessage } from "../../api/sendForm";
import {
  EMAIL_REGEXP,
  NAME_REGEXP,
  PHONE_REGEXP,
  MESSAGE_REGEXP,
} from "../../helpers/regexp";

const ModalForm = () => {
  let modal = useContext(ContextForm);
  const [srcCaptcha, setSrcCaptcha] = useState(
    `${process.env.REACT_APP_BASE_URL}/get-captcha-image-landing`
  );
  const [name, setName] = useState("");
  const [errorName, setErrorName] = useState(true);
  const [emptyName, setEmptyName] = useState(false);
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(true);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState(true);
  const [emptyPhone, setEmptyPhone] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [emptyCaptcha, setEmptyCaptcha] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(true);
  const [emptyMessage, setEmptyMessage] = useState(false);
  const [showSuccessBlock, setShowSuccessBlock] = useState(false);

  const onClose = () => {
    modal.dispatch(false);
    onReloadCaptcha();
  };

  const onReloadCaptcha = () => {
    setSrcCaptcha(
      `${process.env.REACT_APP_BASE_URL}/get-captcha-image-landing` +
        `?r=${new Date().getUTCMilliseconds()}`
    );
  };

  const validateEmail = (value) => {
    return EMAIL_REGEXP.test(value);
  };

  const validateName = (value) => {
    return NAME_REGEXP.test(value);
  };

  const validatePhone = (value) => {
    return PHONE_REGEXP.test(value);
  };

  const validateMessage = (value) => {
    return MESSAGE_REGEXP.test(value);
  };

  const onName = (event) => {
    setName(event.target.value);
    validateName(event.target.value) ? setErrorName(false) : setErrorName(true);
  };

  const onEmail = (event) => {
    setEmail(event.target.value);
    validateEmail(event.target.value)
      ? setErrorEmail(false)
      : setErrorEmail(true);
  };

  const onPhone = (event) => {
    // eslint-disable-next-line no-useless-escape
    let value = event.target.value.replace(/[^\+\d]/g, "");
    setPhone(value);
    validatePhone(value) ? setErrorPhone(false) : setErrorPhone(true);
  };

  const onCaptcha = (event) => {
    setCaptcha(event.target.value);
  };

  const onMessage = (event) => {
    setMessage(event.target.value);
    validateMessage(event.target.value)
      ? setErrorMessage(false)
      : setErrorMessage(true);
  };

  const onSend = () => {
    if (
      errorName === false &&
      name.trim().length > 0 &&
      errorEmail === false &&
      errorPhone === false &&
      captcha.trim().length > 0 &&
      message.trim().length > 0 &&
      errorMessage === false
    ) {
      sendMessage(name, email, phone, message, captcha).then((res) => {
        if (res.status === 200) {
          setShowSuccessBlock(true);
          setTimeout(() => {
            setShowSuccessBlock(false);
            onClose();
          }, 5000);
          setName("");
          setEmail("");
          setPhone("");
          setCaptcha("");
          setMessage("");
          onReloadCaptcha();
        } else if (res.status === 422) {
          setEmptyCaptcha(true);
          setTimeout(() => {
            setEmptyCaptcha(false);
          }, 5000);
        } else {
          console.warn(res);
        }
      });
    } else if (errorName === true || name.trim().length === 0) {
      setEmptyName(true);
      setTimeout(() => {
        setEmptyName(false);
      }, 2000);
    } else if (errorEmail === true || email.trim().length === 0) {
      setEmptyEmail(true);
      setTimeout(() => {
        setEmptyEmail(false);
      }, 2000);
    } else if (errorPhone === true || phone.trim().length === 0) {
      setEmptyPhone(true);
      setTimeout(() => {
        setEmptyPhone(false);
      }, 2000);
    } else if (captcha.trim().length === 0) {
      setEmptyCaptcha(true);
      setTimeout(() => {
        setEmptyCaptcha(false);
      }, 2000);
    } else if (errorMessage === true || message.trim().length === 0) {
      setEmptyMessage(true);
      setTimeout(() => {
        setEmptyMessage(false);
      }, 2000);
    }
  };

  return (
    <>
      <CustomModal
        visible={modal.value}
        title="REQUEST A CALL BACK"
        footer={<button onClick={onSend}>Send</button>}
        onClose={onClose}
        srcCaptcha={srcCaptcha}
        onReloadCaptcha={onReloadCaptcha}
        onName={onName}
        onEmail={onEmail}
        onPhone={onPhone}
        onCaptcha={onCaptcha}
        onMessage={onMessage}
        name={name}
        email={email}
        phone={phone}
        captcha={captcha}
        message={message}
        emptyCaptcha={emptyCaptcha}
        emptyName={emptyName}
        emptyEmail={emptyEmail}
        emptyPhone={emptyPhone}
        emptyMessage={emptyMessage}
        showSuccessBlock={showSuccessBlock}
      />
    </>
  );
};

export default ModalForm;
