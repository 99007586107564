import React, { useState, useEffect } from "react";
import "./../../styles/header.css";
import logoGold from "../../assets/images/logo_footer.png";
import { FiLogIn } from "react-icons/fi";
import { BsPersonCheckFill } from "react-icons/bs";
import Link from "react-scroll/modules/components/Link";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ourServicesContent } from "../../content/ourServicesContent";

const Header = () => {
  const [isService, setIsService] = useState(false);
  const [cookie, setCookie] = useState(false);
  const [offset, setOffset] = useState(0);
  let { pathname, key } = useLocation();

  useEffect(() => {
    window.document.onscroll = () => {
      setOffset(window.scrollY);
    };

    document.cookie.split(";").forEach((item) => {
      if (item.split("=")[0] === "auth_token") {
        setCookie(true);
      }
    });
  }, []);

  useEffect(() => {
    ourServicesContent.forEach((item) => {
      if (
        item.buttonConsultationLink === pathname ||
        pathname === "/privacy-policy" ||
        pathname === "/terms-of-use" ||
        pathname === "/our-team" ||
        pathname.split("/")[1] === "blog"
      ) {
        setIsService(true);
      }
    });
  }, [pathname]);

  return (
    <header className="header" id="home-scroll">
      <div
        className={
          !isService ? "header__container" : "header__containerService"
        }
        style={
          offset > 750 && (key !== "default" || pathname === "/")
            ? { position: "fixed" }
            : null
        }
      >
        <div
          className="header__navbar"
          style={
            offset > 200 && offset < 750 && pathname === "/"
              ? { marginTop: "-180px" }
              : null
          }
        >
          <div className="header__logo">
            {pathname === "/" || key === "default" ? (
              <>
                <Link
                  activeClass="active"
                  to="home-scroll"
                  spy={true}
                  smooth={true}
                  duration={2000}
                  delay={100}
                >
                  <span
                    className={
                      offset > 750 && (key !== "default" || pathname === "/")
                        ? "header__logo_img-sticky"
                        : "header__logo_img"
                    }
                  >
                    <img width={48} src={logoGold} alt="logo Emirates Law" />
                  </span>
                </Link>
                <Link
                  activeClass="active"
                  to="home-scroll"
                  spy={true}
                  smooth={true}
                  duration={2000}
                  delay={100}
                >
                  <span
                    className={
                      offset > 750 && pathname === "/"
                        ? "header__logo_text-sticky"
                        : "header__logo_text"
                    }
                  >
                    Emirates.Law
                  </span>
                </Link>
              </>
            ) : (
              <>
                <NavLink to="/">
                  <span className="header__logo_img-sticky">
                    <img width={36} src={logoGold} alt="logo Emirates Law" />
                  </span>
                </NavLink>
                <NavLink to="/">
                  <span className="header__logo_textService-sticky">
                    Emirates.Law
                  </span>
                </NavLink>
              </>
            )}
          </div>
          {pathname === "/" ? (
            <nav className="header__nav">
              <ul className="header__menu">
                <li>
                  <Link
                    className="header__menu_item"
                    activeClass="active"
                    to="about-scroll"
                    spy={true}
                    smooth={true}
                    duration={2000}
                    delay={100}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    className="header__menu_item"
                    activeClass="active"
                    to="services-scroll"
                    spy={true}
                    smooth={true}
                    duration={2000}
                    delay={100}
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    className="header__menu_item"
                    activeClass="active"
                    to="mission-scroll"
                    spy={true}
                    smooth={true}
                    duration={2000}
                    delay={100}
                  >
                    Mission
                  </Link>
                </li>
                <li>
                  <NavLink className="header__menu_item" to="/our-team">
                    Team
                  </NavLink>
                </li>
                <li>
                  <Link
                    className="header__menu_item"
                    activeClass="active"
                    to="contacts-scroll"
                    spy={true}
                    smooth={true}
                    duration={2000}
                    delay={100}
                  >
                    Contacts
                  </Link>
                </li>
                {/* <li>
                  <NavLink className="header__menu_item" to="/blog">
                    Blog
                  </NavLink>
                </li> */}
                <li>
                  {cookie ? (
                    <div>
                      <a
                        className={
                          !isService
                            ? "header__item_link"
                            : "header__item_linkService"
                        }
                        href="https://my.emirates.law/auth/sign-in"
                      >
                        <span style={{ marginRight: 10 }}>Profile</span>
                        <BsPersonCheckFill />
                      </a>
                    </div>
                  ) : (
                    <div>
                      <a
                        className={
                          !isService
                            ? "header__item_link"
                            : "header__item_linkService"
                        }
                        href="https://my.emirates.law/auth/sign-in"
                      >
                        <span style={{ marginRight: "10px" }}>Sign In</span>
                        <FiLogIn />
                      </a>
                    </div>
                  )}
                </li>
              </ul>
            </nav>
          ) : (
            <nav className="header__nav">
              <ul className="header__menu">
                <li>
                  <NavLink
                    className={
                      isService
                        ? "header__menu_itemService"
                        : "header__menu_itemError"
                    }
                    to="/"
                    state="about-scroll"
                  >
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={
                      isService
                        ? "header__menu_itemService"
                        : "header__menu_itemError"
                    }
                    to="/"
                    state="services-scroll"
                  >
                    Services
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={
                      isService
                        ? "header__menu_itemService"
                        : "header__menu_itemError"
                    }
                    to="/"
                    state="mission-scroll"
                  >
                    Mission
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={
                      isService
                        ? "header__menu_itemService"
                        : "header__menu_itemError"
                    }
                    to="/our-team"
                  >
                    Team
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={
                      isService
                        ? "header__menu_itemService"
                        : "header__menu_itemError"
                    }
                    to="/"
                    state="contacts-scroll"
                  >
                    Contacts
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    className={
                      isService
                        ? "header__menu_itemService"
                        : "header__menu_itemError"
                    }
                    to="/blog"
                  >
                    Blog
                  </NavLink>
                </li> */}
                <li>
                  {cookie ? (
                    <div>
                      <a
                        className={
                          !isService
                            ? "header__item_link"
                            : "header__item_linkService"
                        }
                        href="https://my.emirates.law/auth/sign-in"
                      >
                        <span style={{ marginRight: 10 }}>Profile</span>
                        <BsPersonCheckFill />
                      </a>
                    </div>
                  ) : (
                    <div>
                      <a
                        className={
                          !isService
                            ? "header__item_link"
                            : "header__item_linkService"
                        }
                        href="https://my.emirates.law/auth/sign-in"
                      >
                        <span style={{ marginRight: "10px" }}>Sign In</span>
                        <FiLogIn />
                      </a>
                    </div>
                  )}
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
