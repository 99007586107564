export const footerContent = {
  logo: "Emirates.Law",
  links: [
    { id: 1, name: "Criminal", link: "/best-criminal-lawyers-dubai" },
    { id: 2, name: "Real Estate", link: "/best-real-estate-lawyer-dubai" },
    { id: 3, name: "Commercial", link: "/commercial-lawyers-dubai" },
    { id: 4, name: "Insurance", link: "/best-insurance-lawyers-uae" },
    { id: 5, name: "Civil", link: "/civil-lawyers-dubai" },
    { id: 6, name: "Family", link: "/family-law-dubai" },
    { id: 7, name: "Maritime", link: "/maritime" },
    { id: 8, name: "Labour", link: "/labour-lawyer-dubai" },
    { id: 9, name: "Banking", link: "/uae-banking-and-finance-lawyers" },
    {
      id: 10,
      name: "Investment",
      link: "/investment-funds-lawyers-services-dubai",
    },
  ],

  titleTel: "Phone",
  tel: "+971 4 578 6050",
  titleEmail: "Email",
  email: "legal@emirates.law",
  titleAddress: "Address",
  address:
    "2006 Opal Tower Burj Khalifa Blvd, Business Bay P.O. Box 90678 Dubai — UAE.",
  addressLink: "https://goo.gl/maps/56mZgu3K2bA3tzNg8",
  description:
    "Raya Al Ameri law firm team consists of highly qualified and experienced lawyers boasting the largest and broadest full-service Litigation Practice in UAE. We handle cases from local litigation to complex disputes. We have the technical expertise and capability to advise clients and conduct all types of litigation in the UAE including but not limited Commercial, Employment, Banking, Civil, Insurance, Construction, Real Estate, IP, and Criminal Cases.",
  linkedin: "https://www.linkedin.com/company/emirateslaw/",
  instagram: "https://www.instagram.com/emirates_law_official/",
  twitter: "https://twitter.com/Emirates__Law",
  facebook: "https://www.facebook.com/emirates.law.official",
  resived: "2022 ©  All rights reserved.",
  policy: "Privacy Policy",
  policyLink: "/privacy-policy",
  termsOfUse: "Terms Of Use",
  termsOfUseLink: "/terms-of-use",
  dib: "Website by dib.company",
};
