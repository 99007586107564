import React, { useState, useEffect } from "react";
import Link from "react-scroll/modules/components/Link";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
import { BsPersonCheckFill } from "react-icons/bs";

const Links = ({ setChecked }) => {
  let { pathname } = useLocation();
  const [cookie, setCookie] = useState(false);

  useEffect(() => {
    document.cookie.split(";").forEach((item) => {
      if (item.split("=")[0] === "auth_token") {
        setCookie(true);
      }
    });
  }, []);

  return (
    <>
      {pathname === "/" ? (
        <>
          <li>
            {cookie ? (
              <div>
                <a
                  href="https://my.emirates.law/auth/sign-in"
                  style={{ backgroundColor: "#bf986c" }}
                >
                  <span style={{ marginRight: 10 }}>Profile</span>
                  <BsPersonCheckFill />
                </a>
              </div>
            ) : (
              <div>
                <a
                  href="https://my.emirates.law/auth/sign-in"
                  style={{ backgroundColor: "#bf986c" }}
                >
                  <span style={{ marginRight: "10px" }}>Sign In</span>
                  <FiLogIn />
                </a>
              </div>
            )}
          </li>
          <li>
            <Link
              activeClass="active"
              to="about-scroll"
              spy={true}
              smooth={true}
              duration={2000}
              delay={100}
              onClick={() => {
                setChecked(false);
              }}
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="services-scroll"
              spy={true}
              smooth={true}
              duration={2000}
              delay={100}
              onClick={() => {
                setChecked(false);
              }}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="mission-scroll"
              spy={true}
              smooth={true}
              duration={2000}
              delay={100}
              onClick={() => {
                setChecked(false);
              }}
            >
              Mission
            </Link>
          </li>
          <li>
            <NavLink
              to="/our-team"
              onClick={() => {
                setChecked(false);
              }}
            >
              Team
            </NavLink>
          </li>
          <li>
            <Link
              activeClass="active"
              to="contacts-scroll"
              spy={true}
              smooth={true}
              duration={2000}
              delay={100}
              onClick={() => {
                setChecked(false);
              }}
            >
              Contacts
            </Link>
          </li>
          {/* <li>
            <NavLink
              to="/blog"
              onClick={() => {
                setChecked(false);
              }}
            >
              Blog
            </NavLink>
          </li> */}
        </>
      ) : (
        <>
          <li>
            {cookie ? (
              <div>
                <a
                  href="https://my.emirates.law/auth/sign-in"
                  style={{ backgroundColor: "#bf986c" }}
                >
                  <span style={{ marginRight: 10 }}>Profile</span>
                  <BsPersonCheckFill />
                </a>
              </div>
            ) : (
              <div>
                <a
                  href="https://my.emirates.law/auth/sign-in"
                  style={{ backgroundColor: "#bf986c" }}
                >
                  <span style={{ marginRight: "10px" }}>Sign In</span>
                  <FiLogIn />
                </a>
              </div>
            )}
          </li>
          <li>
            <NavLink
              to="/"
              state="about-scroll"
              onClick={() => {
                setChecked(false);
              }}
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/"
              state="services-scroll"
              onClick={() => {
                setChecked(false);
              }}
            >
              Services
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/"
              state="mission-scroll"
              onClick={() => {
                setChecked(false);
              }}
            >
              Mission
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/our-team"
              onClick={() => {
                setChecked(false);
              }}
            >
              Team
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/"
              state="contacts-scroll"
              onClick={() => {
                setChecked(false);
              }}
            >
              Contacts
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/blog"
              onClick={() => {
                setChecked(false);
              }}
            >
              Blog
            </NavLink>
          </li> */}
        </>
      )}
    </>
  );
};

export default Links;
