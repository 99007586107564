import React from "react";
import "./../../styles/main.css";

import Slider from "./Slider";

import MainBanner from "./MainBanner";
import HeaderToggle from "../HeaderToggle/HeaderToggle";

const Main = () => {
  return (
    <>
      <section className="main">
        <MainBanner />
      </section>
      <section className="main-slider" id="home-scroll-toggle">
        <Slider />
        <HeaderToggle />
      </section>
    </>
  );
};

export default Main;
