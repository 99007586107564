import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import preloaderImg from "./assets/images/logo_footer.png";
import Footer from "./components/Footer/Footer";
import Main from "./components/Main/Main";
import NavigationRight from "./components/NavigationRight/NavigationRight";
import OurCompany from "./components/OurCompany/OurCompany";
import OurMission from "./components/OurMission/OurMission";
import OurServices from "./components/OurServices/OurServices";
import { useLocation } from "react-router-dom";

const App = () => {
  const [preloader, setPreloader] = useState(true);
  const { state } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    setTimeout(() => {
      setPreloader(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (state) {
      setTimeout(() => {
        document.getElementById(state).scrollIntoView({ behavior: "smooth" });
        window.history.replaceState({}, document.title);
      }, 2500);
    }
  }, [state]);

  if (preloader) {
    return (
      <div className="preloader_box">
        <img width={54} src={preloaderImg} alt="Loading..." />
        <div className="preloader_text">Emirates.Law</div>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Emirates Law</title>
      </Helmet>

      <Main />
      <main>
        <OurCompany />
        <OurServices />
        <OurMission />
      </main>
      <Footer />
      <NavigationRight />
    </div>
  );
};

export default App;
