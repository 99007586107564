import * as axios from "axios";

export const sendMessage = async (name, email, phone, message, captcha) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/contact`,
      { name, email, phone, message, captcha }
    );
    return response;
  } catch (e) {
    return e?.response;
  }
};
