export const ourServicesContent = [
  {
    id: 1,
    title: "Criminal Law",
    img: "criminal_law",
    button: "read more",
    buttonLink: "/#",
    description:
      "We support our clients in all aspects of criminal litigation proceedings, where we have an in-depth knowledge of local laws and provide exceptional legal advice in all aspects of criminal litigation.",
    buttonConsultation: "Book Consultation",
    buttonConsultationLink: "/best-criminal-lawyers-dubai",
  },
  {
    id: 2,
    title: "Civil Law",
    img: "civil_law",
    button: "read more",
    buttonLink: "/#",
    description:
      "Our Civil Law team has years of experience in representing clients before the court in various cases from the daily life of individuals, establishments, and companies. You will get to-the-point legal advice in all aspects of civil litigations including but not limited to, compensation litigations, breach of civil contracts, and financial claims.",
    buttonConsultation: "Book Consultation",
    buttonConsultationLink: "/civil-lawyers-dubai",
  },
  {
    id: 3,
    title: "Real Estate",
    img: "real_estate",
    button: "read more",
    buttonLink: "/#",
    description:
      "The real estate sector represents a significant element of the UAE economy. Raya Al Ameri Advocates & Legal Consultancy provides sound commercial advice on all issues relevant to real estate matters in UAE.",
    buttonConsultation: "Book Consultation",
    buttonConsultationLink: "/best-real-estate-lawyer-dubai",
  },
  {
    id: 4,
    title: "Commercial Law",
    img: "commercial",
    button: "read more",
    buttonLink: "/#",
    description:
      "The United Arab Emirates is now considered one of the most stable and prosperous markets in the world. Our experts represent foreign and domestic clients in all fields of commercial law.",
    buttonConsultation: "Book Consultation",
    buttonConsultationLink: "/commercial-lawyers-dubai",
  },
  {
    id: 5,
    title: "Insurance",
    img: "insurance",
    button: "read more",
    buttonLink: "/#",
    description:
      "Raya Al Ameri Advocates & Legal Consultancy operates a specialist Insurance Practice to cater to all aspects of the UAE’s insurance industry. Our advisory ranges from all types of insurance protection and claims to corporates in a wider range of industries.",
    buttonConsultation: "Book Consultation",
    buttonConsultationLink: "/best-insurance-lawyers-uae",
  },
  {
    id: 6,
    title: "Family Law",
    img: "family_law",
    button: "read more",
    buttonLink: "/#",
    description:
      "Our experienced team will provide consultations and full representation in all stages of family matters, proceedings, and disputes to clients regardless of nationality or religion.",
    buttonConsultation: "Book Consultation",
    buttonConsultationLink: "/family-law-dubai",
  },
  {
    id: 7,
    title: "Maritime Law",
    img: "maritime",
    button: "read more",
    buttonLink: "/#",
    description:
      "At Raya Al Ameri Advocates & Legal Consultancy, we deal with all maritime disputes arising from the breach of contractual obligations.",
    buttonConsultation: "Book Consultation",
    buttonConsultationLink: "/maritime",
  },
  {
    id: 8,
    title: "Labour Law",
    img: "labour",
    button: "read more",
    buttonLink: "/#",
    description:
      "Our team advises employers and employees in dealing with their respective rights and obligations under applicable laws of the UAE, including the interpretation and enforcement of labour agreements and employer manuals.",
    buttonConsultation: "Book Consultation",
    buttonConsultationLink: "/labour-lawyer-dubai",
  },
  {
    id: 9,
    title: "Banking & Finance",
    img: "banking",
    button: "read more",
    buttonLink: "/#",
    description:
      "The sporadic changes in the financial, institutional, and regulatory framework in UAE have transformed the way banking business is conducted. Our experts advise our clients in adapting to the new banking framework and achieving the business objectives.",
    buttonConsultation: "Book Consultation",
    buttonConsultationLink: "/uae-banking-and-finance-lawyers",
  },
  {
    id: 10,
    title: "Investment Fund",
    img: "investment",
    button: "read more",
    buttonLink: "/#",
    description:
      "We offer professional representation services for foreign fund houses and asset management companies (ACM) who wish to promote and distribute their funds in the United Arab Emirates.",
    buttonConsultation: "Book Consultation",
    buttonConsultationLink: "/investment-funds-lawyers-services-dubai",
  },
];
