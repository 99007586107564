import { ourMissionContent } from "../../content/ourMissionContents";
import { ourVisionContent } from "../../content/ourVisionContent";
import "../../styles/ourMission.css";

const OurMission = () => {
  return (
    <section className="ourMission" id="mission-scroll">
      <div className="ourMission__container">
        <h2 className="ourMission__title">Our Mission</h2>
        <p className="ourMission__content">{ourMissionContent.textTop}</p>
        <p className="ourMission__content">{ourMissionContent.textMiddle}</p>
        <p className="ourMission__content">{ourMissionContent.textBottom}</p>

        <h2 className="ourMission__title">Our Vision</h2>
        <p className="ourMission__content">{ourVisionContent.content}</p>
      </div>
    </section>
  );
};

export default OurMission;
