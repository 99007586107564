import React, { useState } from "react";
import "../../styles/headerToggle.css";
import logoBurgerGold from "../../assets/images/logo-burger-gold.svg";
import Links from "./Links";
import { NavLink } from "react-router-dom";
import Link from "react-scroll/modules/components/Link";
import { useLocation } from "react-router-dom";

const HeaderToggle = () => {
  const [checked, setChecked] = useState(false);
  let { key, pathname } = useLocation();

  const onShowMenu = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <section
        className={
          checked && key !== "default" && pathname !== "/"
            ? "header-toggle-active"
            : "header-toggle"
        }
        style={checked ? { backgroundColor: "#fff" } : null}
      >
        <div className="logo">
          <div className="logo-box">
            {pathname === "/" ? (
              <>
                <Link
                  activeClass="active"
                  to="home-scroll-toggle"
                  spy={true}
                  smooth={true}
                  duration={2000}
                  delay={100}
                  onClick={() => {
                    setChecked(false);
                  }}
                >
                  <img
                    width={38}
                    className="logo-img"
                    src={logoBurgerGold}
                    alt="Emirates Law"
                  />
                </Link>
                <div>
                  <Link
                    activeClass="active"
                    to="home-scroll-toggle"
                    spy={true}
                    smooth={true}
                    duration={2000}
                    delay={100}
                    onClick={() => {
                      setChecked(false);
                    }}
                  >
                    <div className="logo-text">Emirates</div>
                    <div className="logo-text">Law</div>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <NavLink to="/">
                  <img
                    width={38}
                    className="logo-img"
                    src={logoBurgerGold}
                    alt="logo Emirates Law"
                  />
                </NavLink>
                <div>
                  <NavLink to="/">
                    <div className="logo-text">Emirates</div>
                    <div className="logo-text">Law</div>
                  </NavLink>
                </div>
              </>
            )}
          </div>
        </div>
        <input
          className="side-menu"
          type="checkbox"
          id="side-menu"
          onChange={onShowMenu}
          checked={checked}
        />
        <label className="hamb" htmlFor="side-menu">
          <span
            className={
              checked || (key !== "default" && pathname !== "/")
                ? "hamb-line-consultation"
                : "hamb-line"
            }
          ></span>
        </label>
        <nav className="nav">
          <ul className="menu">
            <Links setChecked={setChecked} />
          </ul>
        </nav>
      </section>
    </>
  );
};

export default HeaderToggle;
