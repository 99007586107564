import React, { useEffect } from "react";
import { FaTimes, FaSyncAlt } from "react-icons/fa";

const CustomModal = ({
  visible = false,
  title = "",
  footer = "",
  onClose,
  srcCaptcha,
  onReloadCaptcha,
  onName,
  onEmail,
  onPhone,
  onCaptcha,
  onMessage,
  name,
  email,
  phone,
  captcha,
  message,
  emptyCaptcha,
  emptyName,
  emptyEmail,
  emptyPhone,
  emptyMessage,
  showSuccessBlock,
}) => {
  const onKeydown = (event) => {
    if (event.key === "Escape" && visible === true) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeydown);
    return () => document.addEventListener("keydown", onKeydown);
  });

  if (!visible) return null;

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal__dialog" onClick={(e) => e.stopPropagation()}>
        <div className="modal__header">
          <div className="modal__title">{title}</div>
          <span className="modal__close" onClick={onClose}>
            <FaTimes />
          </span>
        </div>
        <div className="modal__body">
          {!showSuccessBlock ? (
            <div className="modal__content">
              <div className="modal__content_title">
                We support our clients in all aspects.
              </div>
              <div
                className={
                  emptyName
                    ? "modal__content_name-error"
                    : "modal__content_name"
                }
              >
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  onChange={onName}
                  maxLength={24}
                  value={name}
                />
              </div>
              <div
                className={
                  emptyEmail
                    ? "modal__content_email-error"
                    : "modal__content_email"
                }
              >
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={onEmail}
                  value={email}
                />
              </div>
              <div
                className={
                  emptyPhone ? "modal__content_tel-error" : "modal__content_tel"
                }
              >
                <input
                  type="tel"
                  placeholder="Phone"
                  name="phone"
                  onChange={onPhone}
                  value={phone}
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
              </div>
              <div className="modal__content_captcha">
                <img
                  width={155}
                  className="modal__content_captcha-img"
                  src={srcCaptcha}
                  alt="captcha"
                />
                <button
                  className="modal__content_captcha-button"
                  onClick={onReloadCaptcha}
                >
                  <FaSyncAlt />
                </button>
                <input
                  className={
                    emptyCaptcha
                      ? "modal__content_captcha-input-error"
                      : "modal__content_captcha-input"
                  }
                  type="text"
                  placeholder="Captcha"
                  onChange={onCaptcha}
                  value={captcha}
                />
              </div>
              <div className="modal__content_message">
                <textarea
                  className={
                    emptyMessage
                      ? "modal__content_message-textarea-error"
                      : "modal__content_message-textarea"
                  }
                  placeholder="Your message"
                  maxLength={255}
                  onChange={onMessage}
                  value={message}
                ></textarea>
              </div>
            </div>
          ) : (
            <div className="modal__success">
              The message was sent successfully!
            </div>
          )}
        </div>
        {footer && !showSuccessBlock ? (
          <div className="modal__footer">{footer}</div>
        ) : null}
      </div>
    </div>
  );
};

export default CustomModal;
