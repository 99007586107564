import React from "react";
import "../../styles/ourCompany.css";
import { ourCompanyContent } from "../../content/ourCompanyContent";

const OurCompany = () => {
  return (
    <section className="ourCompany" id="about-scroll">
      <div className="ourCompany__container">
        <h2 className="ourCompany__title">Our Company</h2>
        <p className="ourCompany__content">{ourCompanyContent.textTop}</p>
        <p className="ourCompany__content">{ourCompanyContent.textBottom}</p>
      </div>
    </section>
  );
};

export default OurCompany;
