export const mainContent = [
  {
    id: 1,
    titleTop: "Ask",
    titleBottom: "a lawyer",
    subtitle:
      "If you have any questions or are in need of assistance in your legal process or business operations, don't hesitate to ask.",
    link: "/#",
    linkText: "Ask",
    slideBg: "ask-a-lawyer-mobile.webp",
    mainBg: "ask-a-lawyer-desktop.webp",
  },
  {
    id: 2,
    titleTop: "Find",
    titleBottom: "a lawyer",
    subtitle:
      "Whatever your case may be, we at Raya Al Ameri Law firm will appoint you with a team member that is experienced and has a high level of expertise in the area of your specific case.",
    link: "/#",
    linkText: "Find",
    slideBg: "find-a-lawyer-mobile.webp",
    mainBg: "find-a-lawyer-desktop.webp",
  },
  {
    id: 3,
    titleTop: "Hire",
    titleBottom: "a lawyer",
    subtitle:
      "If you have an ongoing or pending legal proceeding or are in need of advice, contact us and hire a lawyer specialized in your matter.",
    link: "/#",
    linkText: "Hire",
    slideBg: "hire-a-lawyer-mobile.webp",
    mainBg: "hire-a-lawyer-desktop.webp",
  },
  {
    id: 4,
    titleTop: "Explore",
    titleBottom: "Legal Info",
    subtitle:
      "We provide full-scope and comprehensive legal service and work as a single point where you can find answers to whatever issues that you face. All our legal opinions and documents that we make, are backed with detailed and pinpoint legal research.",
    link: "/#",
    linkText: "Explore",
    slideBg: "explore-legal-info-mobile.webp",
    mainBg: "explore-legal-info-desktop.webp",
  },
];
