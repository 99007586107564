import React, { useState, lazy, Suspense } from "react";
import ContextForm from "./contexts/ContextForm";
import App from "./App";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ModalForm from "./components/ModalForm/ModalForm";

const LazyOurTeam = lazy(() =>
  import("./components/OurTeam/OurTeam" /* webpackChunkName: "our-team-page" */)
);
const LazyCivil = lazy(() =>
  import(
    "./components/OurServices/BookConsultation/Civil" /* webpackChunkName: "civic-page" */
  )
);
const LazyCommercial = lazy(() =>
  import(
    "./components/OurServices/BookConsultation/Commercial" /* webpackChunkName: "commercial-page" */
  )
);
const LazyCriminal = lazy(() =>
  import(
    "./components/OurServices/BookConsultation/Criminal" /* webpackChunkName: "criminal-page" */
  )
);
const LazyFamily = lazy(() =>
  import(
    "./components/OurServices/BookConsultation/Family" /* webpackChunkName: "family-page" */
  )
);
const LazyFinance = lazy(() =>
  import(
    "./components/OurServices/BookConsultation/Finance" /* webpackChunkName: "finance-page" */
  )
);
const LazyInsurance = lazy(() =>
  import(
    "./components/OurServices/BookConsultation/Insurance" /* webpackChunkName: "insurance-page" */
  )
);
const LazyMaritime = lazy(() =>
  import(
    "./components/OurServices/BookConsultation/Maritime" /* webpackChunkName: "maritime-page" */
  )
);
const LazyLabour = lazy(() =>
  import(
    "./components/OurServices/BookConsultation/Labour" /* webpackChunkName: "labour-page" */
  )
);
const LazyRealEstate = lazy(() =>
  import(
    "./components/OurServices/BookConsultation/RealEstate" /* webpackChunkName: "real-estate-page" */
  )
);
const LazyInvestment = lazy(() =>
  import(
    "./components/OurServices/BookConsultation/Investment" /* webpackChunkName: "investment-page" */
  )
);
const LazyPrivacyPolicy = lazy(() =>
  import(
    "./components/PrivacyPolicy/PrivacyPolicy" /* webpackChunkName: "privacy-policy-page" */
  )
);
const LazyTermsOfUse = lazy(() =>
  import(
    "./components/TermsOfUse/TermsOfUse" /* webpackChunkName: "terms-of-use-page" */
  )
);
const LazyError = lazy(() =>
  import("./components/Error/Error" /* webpackChunkName: "error-page" */)
);

const LazyBlog = lazy(() =>
  import("./components/Blog/Blog" /* webpackChunkName: "blog-page" */)
);

const AppContainer = () => {
  const [modalForm, setModalForm] = useState(false);

  const showModalForm = () => {
    setModalForm(!modalForm);
  };

  const valueContextForm = {
    value: modalForm,
    dispatch: showModalForm,
  };

  return (
    <>
      <ContextForm.Provider value={valueContextForm}>
        <Router>
          <Suspense fallback="Loading...">
            <Routes>
              <Route exact path="/" element={<App />} />
              <Route path="/our-team" element={<LazyOurTeam />} />
              <Route path="/civil-lawyers-dubai" element={<LazyCivil />} />
              <Route
                path="/commercial-lawyers-dubai"
                element={<LazyCommercial />}
              />
              <Route
                path="/best-criminal-lawyers-dubai"
                element={<LazyCriminal />}
              />
              <Route path="/family-law-dubai" element={<LazyFamily />} />
              <Route
                path="/uae-banking-and-finance-lawyers"
                element={<LazyFinance />}
              />
              <Route
                path="/best-insurance-lawyers-uae"
                element={<LazyInsurance />}
              />
              <Route path="/maritime" element={<LazyMaritime />} />
              <Route path="/labour-lawyer-dubai" element={<LazyLabour />} />
              <Route
                path="/best-real-estate-lawyer-dubai"
                element={<LazyRealEstate />}
              />
              <Route
                path="/investment-funds-lawyers-services-dubai"
                element={<LazyInvestment />}
              />
              <Route path="/privacy-policy" element={<LazyPrivacyPolicy />} />
              <Route path="/terms-of-use" element={<LazyTermsOfUse />} />
              <Route path="/blog/*" element={<LazyBlog />} />
              <Route path="*" element={<LazyError />} />
            </Routes>
          </Suspense>
          <ModalForm />
        </Router>
      </ContextForm.Provider>
    </>
  );
};

export default AppContainer;
